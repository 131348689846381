@use "@/styles/mixins/media-queries" as *;


.image {
  z-index: -1;
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}


.desktop {
  display: none;
  @include desktop-and-up {
    display: block;
  }
}

.tablet {
  display: none;
  @include tablet-landscape-only {
    display: block;
  }
}

.mobile {
  display: block;

  @include tablet-landscape-and-up {
    display: none;
  }
}