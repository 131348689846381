@use "@/styles/mixins/media-queries" as *;
@use "@/styles/mixins/max-root-width" as *;
@use "@/styles/mixins/line-clamp" as *;
@import "@/styles/mixins/entity-header-gradient";


.container {
  display: flex;
  position: relative;

}

.gradient {
  background: $gradient;
}

.exceptionBgGradient{
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, #000000 100%);
}

.content {
  --entity-logo-size: 4rem;
  display: grid;
  gap: 1rem;
  padding-bottom: 6.25rem;
  align-items: center;
  justify-items: center;
  z-index: 0;
  text-align: center;

  @include tablet-portrait-and-up {
    gap: 2rem;
    --entity-logo-size: 6rem;
    grid-template-columns: var(--entity-logo-size) auto;
    text-align: start;
    justify-items: start;
  }

  @include tablet-landscape-and-up {
    --entity-logo-size: 7.5rem;
  }
}

.image {
  z-index: -1;
  object-fit: cover;
}

.title {
  @include line-clamp(4);
  word-wrap: break-word;
  max-width: -webkit-fill-available;
}

.logo {
  --logo-size: 6rem;
  width: var(--logo-size);
  height: var(--logo-size);

  @include tablet-landscape-and-up {
    --logo-size: 7.5rem;
  }
}

.limitWrapper {
  @include limit;
  display: grid;
  width: 100%;
  padding-top: 2rem;
  gap: 3rem;

  @include tablet-landscape-and-up {
    gap: 0;
  }

}

.followButton {
  justify-self: flex-end;
  z-index: var(--entity-title-follow-button-z-index);
}