.container {
  text-decoration: none;
  gap: 0.75rem;
}

// we want to reduce the changes on favourite to avoid misleading the user
.favourite:hover {
  --button-hover-bg-color: var(--primary-background-color);
  --button-hover-border-color: var(--divider-color);
  --button-active-border-color: var(--divider-color);
}