@use "@/styles/mixins/max-root-width" as *;
@use "@/styles/mixins/media-queries" as *;

.container {
  @include limit;
  display: grid;
  place-items: center;
  gap: 1.5rem;
  text-align: center;
}

.text {
  color: var(--secondary-text-color);
}

.image {
  --size: 12.5rem;
  width: var(--size);
  height: var(--size);
}
