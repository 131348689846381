@use "@/styles/mixins/font";
@use "@/styles/mixins/media-queries" as *;

.pageTabs {
  --overlay-size: 3ch;
  position: relative;
  border-bottom: 1px solid var(--divider-color);
  overflow-x: scroll;
  scrollbar-width: none;
  will-change: scroll-position;
}

.list {
  display: flex;
  gap: 1rem;
  width: max-content;
  min-width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet-portrait-and-up {
    gap: 1.5rem;
  }
}

// overlay on the right side of the tabs
.list::after {
  position: sticky;
  top: 0;
  right: 0;
  content: "";
  display: block;
  width: var(--overlay-size);
  background: linear-gradient(
                  90deg,
                  var(--page-tabs-overlay-color),
                  var(--primary-background-color)
  );
  height: 1.5rem;
}

.listItem {
  display: flex;
  align-items: center;

  &:first-child {
    margin-inline-start: var(--root-side-padding);
  }
}

.link {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  color: var(--label-text-color);
  text-decoration: none;
  padding-bottom: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25em;
  @include font.medium;
  text-transform: capitalize;

  @include tablet-portrait-and-up {
    font-size: 1rem;
    padding-bottom: 0.75rem;
  }

  @include desktop-and-up {
    padding-bottom: 1rem;
  }
}

.linkActive {
  color: var(--primary-text-color);
  border-bottom: 2px solid currentColor;
}

:global {
  @include light-mode {
    :local {
      .pageTabs {
        --page-tabs-overlay-color: rgba(255, 255, 255, 0.001);
      }
    }
  }

  @include dark-mode {
    :local {
      .pageTabs {
        --page-tabs-overlay-color: rgba(0, 0, 0, 0.001);
      }
    }
  }
}